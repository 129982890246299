import { graphql, Link } from "gatsby";
import * as React from "react";
import Layout from "../components/layout/layout";
import { parseStory } from "../components/utils";
import arr from "../resources/assets/arr-right.svg";

// markup
const Projects = ({ data }) => {
  const projects = data.projects.nodes.map(parseStory);
  console.log(projects);
  return (
    <Layout>
      <div className="py-6 md:py-12 as-cont">
        <h1 className="as-h1 flex justify-between items-center">Projects</h1>
        {/* <p className="mt-4 text-lg md:text-xl leading-tight max-w-prose">
          Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum
          urna sed consectetur neque tristique pellentesque. Blandit amet, sed
          aenean erat arcu morbi.
        </p> */}
        <div className=" gap-8 mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 pb-2">
          {projects.map((p) => (
            <ProjectCard p={p} key={p.slug} />
          ))}
        </div>

        {/* <div className="flex gap-4 flex-wrap my-4">
          {projects.map((p) => (
            <a
              href={"#" + p.slug}
              className="max-w-full cursor-pointer bg-white border border-gray-900 rounded-full flex gap-0.5 text-gray-900 px-2 py-1 text-sm sm:text-base"
              key={p.slug}
            >
              <span className=" overflow-ellipsis whitespace-nowrap overflow-hidden">
                {p.content.title}
              </span>
              <img src={arr} alt="" className="w-5 rotate-90 flex-shrink-0" />
            </a>
          ))}
        </div>
        {projects.map((p) => (
          <Project p={p} key={p.slug} />
        ))} */}
      </div>
    </Layout>
  );
};

const ProjectCard = ({ p }) => (
  <Link
    to={"/project/" + p.slug}
    className="rounded-xl shadow-lg bg-white overflow-hidden transition-transform hover:-translate-y-2"
  >
    {p.content?.cover_image && (
      <img
        src={p.content?.cover_image?.filename}
        alt={p.content?.cover_image?.alt ?? ""}
        className="w-full aspect-square"
      />
    )}{" "}
    <div className="px-6 py-4">
      <h2 className="font-extrabold text-xl leading-tight mb-4">
        {p.content.title}
      </h2>
      <p>{p.content?.snippet}</p>
      <div className="flex justify-end mt-4">
        <div className="flex group text-sm">
          Read more
          <img
            src={arr}
            alt=""
            className="group-hover:translate-x-1 transition-transform w-5"
          />
        </div>
      </div>
    </div>
  </Link>
);

/* const Project = ({ p }) => (
  <article className="my-6 py-2 relative">
    <div className="-mt-24 absolute top-0" id={p.slug}></div>
    <h1 className="as-h2">{p.content.title}</h1>
    <div className="h-0.5 my-4 w-2/3 bg-slate-300" />
    {(p?.content?.body ?? []).map((block) => getBlock(block))}
  </article>
); */

export const query = graphql`
  query {
    projects: allStoryblokEntry(
      filter: { field_component: { eq: "project" } }
    ) {
      nodes {
        slug
        content
      }
    }
  }
`;

export default Projects;
